import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useI18next } from 'gatsby-plugin-react-i18next';
import { RegisterLayout } from '../../components/register-layout.component';
import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { FormContext } from '../../utils/form.context';
import { resetPassword } from '../../services/users.service';
import { setUser } from '../../states/authentication.slice';
import qs from 'qs';

export default function SetPasswordAccount(props) {

    const { t, navigate } = useI18next();

    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    const token = (query && query.token) ? query.token : null;

    const emailAddress = (query && query.email) ? query.email : null;

    useEffect(() => {
        if (!token || !emailAddress) {
            navigate('/')
        }
    }, [token, emailAddress]);

    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});

    const [errors, setErrors] = useState(null);

    const onResetPassword = useCallback((e) => {
        e.preventDefault();
        setErrors(null);
        resetPassword(emailAddress, token, formValues.password, formValues.confirmPassword)
            .then(response => {
                dispatch(setUser(response.data));
                navigate('/');
            })
            .catch(error => {
                setErrors(error.response.data.message);
            });
    }, [formValues, setErrors, dispatch, navigate]);

    const handleOnChange = useCallback(e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [formValues, setFormValues]);

    const tokenErrorBlock = Array.isArray(errors) ? errors.find(k => k.property === 'token') : null

    return <RegisterLayout disableSecureLayout={true} title={t('Reset password')} className="create-account" backLocation="/account/register">
        <div className="section">
            <FormContext.Provider value={errors}>
                <form onSubmit={onResetPassword} className="flex-form">
                    <FormControl label={t('New password')} name="password">
                        <Input name="password" placeholder="******" value={formValues.password || ''} onChange={handleOnChange} type="password" />
                    </FormControl>
                    <FormControl label={t('Confirm password')} name="confirmPassword">
                        <Input name="confirmPassword" placeholder="******" value={formValues.confirmPassword || ''} onChange={handleOnChange} type="password" />
                    </FormControl>
                    <div className="buttons space-top">
                        <Button type="submit">{t('Reset password')}</Button>
                    </div>
                    {tokenErrorBlock && tokenErrorBlock.errors.map(error =>
                        <div className="hap-FormControl error-state">
                            <span key={error} className="error">{t(error)}</span>
                        </div>
                    )}
                </form>
            </FormContext.Provider>
        </div>
    </RegisterLayout>;
}
